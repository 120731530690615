<template>
  <div class="logo-wrapper d-none d-lg-block">
    <img src="/src/assets/images/ac-logo.png" class="logo-item" alt="Logo Al Capone">
  </div>
  <div class="container section-shape-bg mb-5">
    <div class="row">
      <!-- Spalte links -->
      <div class="col-12 col-md-4">
        <h1 class="text-uppercase fw-bold hyphens-auto my-5" v-html="$t('selection.customerSelection')"></h1>
        <h2 class="h3 text-uppercase fw-medium text-white">{{ $t('selection.description') }}</h2>
      </div>
      <!-- Spalte rechts -->
      <div class="col-12 col-md-6 offset-md-2">
        <h2 class="text-white fw-medium text-uppercase mb-3 mt-5">{{ $t('selection.createCustomer') }}</h2>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="selectionCompany" placeholder="Muster GmbH"
            v-model="newCustomer.name" required>
          <label for="selectionCompany">{{ $t('selection.customerName') }}</label>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="selectionName" placeholder="Max Mustermann"
            v-model="newCustomer.contactPerson">
          <label for="selectionName">{{ $t('selection.customerNumber') }}</label>
        </div>
        <div class="d-flex justify-content-center mb-5">
          <button @click.prevent="createCustomer()" class="btn btn-primary btn-md-white text-uppercase fw-medium px-3"
            :disabled="!newCustomer.name">{{ $t('selection.buttons.create') }}</button>
        </div>
        <h2 class="text-white fw-medium text-uppercase mt-5 mb-3">{{ $t('selection.selectCustomer') }}</h2>
        <div class="form-floating">
          <select class="form-select mb-3" id="selectClient" aria-label="$t('selection.selectCustomerOption')"
            v-model="selectedCustomer">
            <option selected disabled>{{ $t('selection.selectCustomerOption') }}</option>
            <option :key="'customer-' + customer.id" v-for="customer in customers" :value="customer.name">
              {{ customer.name }}<span v-if="customer.contact_person">, {{ customer.contact_person }}</span></option>
          </select>
          <label for="selectClient">{{ $t('selection.customer') }}</label>
        </div>
        <div class="d-flex justify-content-center mb-5">
          <button @click.prevent="selectCustomer()" class="btn btn-primary btn-md-white text-uppercase fw-medium px-3"
            :disabled="!customerSelected">{{ $t('selection.buttons.next') }}</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isOffline" @click="syncData()"
    class="btn btn-primary btn-md-white btn-sync text-uppercase fw-medium d-flex align-items-center gap-1 px-3">
    <i class="icon-sync"></i>{{ $t('selection.buttons.sync') }}
  </div>
</template>

<script>
import router from '@/router';
import {
  useAxios
} from '@/axios';
const axios = useAxios;

export default {
  name: "Selection",
  data() {
    return {
      customers: [],
      selectedCustomer: {},
      newCustomer: {
        name: null,
        contactPerson: null
      },
      isOffline: false,
    }
  },
  computed: {
    // check if customer was selected
    customerSelected() {
      if (this.selectedCustomer.length >= 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // route to selected customer
    selectCustomer() {
      let customerId = this.customers.find(customer => customer.name === this.selectedCustomer);
      router.push(`/selection/${customerId.id}`);
    },
    // reload page to update service worker and sync all data
    syncData() {
      localStorage.setItem("reload", true);
      window.location.reload(true);
    },
    // create/add new customer
    async createCustomer() {
      // check if input for new customer has content
      if (this.newCustomer.name) {
        // if online post new customer to API and add to list
        if (!this.isOffline) {
          await axios.post('customer/add', {
            name: this.newCustomer.name,
            customerNumber: null,
            contactPerson: this.newCustomer.contactPerson || null
          });
          await axios.get('customer/get')
            .then(response => {
              let customers = response.data.customers;
              localStorage.setItem("customers", JSON.stringify(customers));
              this.customers = customers;
            });
          // if offline set new customer in localStorage and add to list
        } else {
          this.customers.push({
            id: this.customers[this.customers.length - 1].id + 1,
            name: this.newCustomer.name,
            customer_number: null,
            contact_person: this.newCustomer.contactPerson || null,
            questions: []
          });
          localStorage.setItem("customers", JSON.stringify(this.customers));

          // add new customer if localStorage entry is already set 
          if (await JSON.parse(localStorage.getItem("newCustomers"))) {
            let newCustomers = await JSON.parse(localStorage.getItem("newCustomers"));
            newCustomers.push({
              name: this.newCustomer.name,
              customerNumber: null,
              contactPerson: this.newCustomer.contactPerson || null
            });
            localStorage.setItem("newCustomers", JSON.stringify(newCustomers));
            // set new localStorage entry for new customer if newCustomers doesn't exist in localStorage
          } else {
            localStorage.setItem("newCustomers", JSON.stringify([{
              name: this.newCustomer.name,
              customerNumber: null,
              contactPerson: this.newCustomer.contactPerson || null
            }]));
          }
        }
        // route to new customer
        let customerId = this.customers.find(customer => customer.name === this.newCustomer.name);
        await router.push(`/selection/${customerId.id}`)
      }
    }
  },
  async mounted() {
    if (localStorage.getItem("reload")) {
      setTimeout(() => {
        localStorage.removeItem("reload");
        window.location.reload(true);
      }, 500)
    }

    // get all customers
    let customers = await JSON.parse(localStorage.getItem("customers"));
    this.customers = customers;

    // check for network status
    if (window.navigator.onLine) {
      this.isOffline = false;
    } else {
      this.isOffline = true;
    }

    window.addEventListener('offline', () => {
      this.isOffline = true;
    });

    window.addEventListener('online', () => {
      this.isOffline = false;
    });
  },
  // before entering the route check if we came from login
  // if we came from login and online get all customers and questions and set to localStorage
  // and reload the page to get all new data
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.isOffline && from.path == '/login') {
        axios.get('customer/get')
          .then(response => {
            let customers = response.data.customers
            localStorage.setItem("customers", JSON.stringify(customers));
          })
        axios.get('question/get')
          .then(response => {
            let questions = response.data.questions
            localStorage.setItem("questions", JSON.stringify(questions));
          })

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    })
  },
}
</script>