/* eslint-disable no-console */

import {
  register
} from 'register-service-worker'
import {
  useAxios
} from '@/axios';
const axios = useAxios;

if (process.env.NODE_ENV === 'production') {
  register(`${window.origin}/sw.js`, {
    ready() {
    },
    async registered() {
      if (await JSON.parse(localStorage.getItem("newCustomers"))) {
        let newCustomers = await JSON.parse(localStorage.getItem("newCustomers"));

        for (const newCustomer of newCustomers) {
          await axios.post('customer/add', {
            name: newCustomer.name,
            customerNumber: null,
            contactPerson: newCustomer.contactPerson || null
          });
        }
        localStorage.removeItem("newCustomers");
      }

      if (await JSON.parse(localStorage.getItem("newCustomerQuestions"))) {
        let newCustomerQuestions = await JSON.parse(localStorage.getItem("newCustomerQuestions"));

        while (newCustomerQuestions.length > 0) {
          let customerQuestions = newCustomerQuestions.pop();
          await axios.post(`/customer/questions/set/${customerQuestions.customer}`, {
            questions: customerQuestions.questions
          });
        }
        localStorage.setItem("newCustomerQuestions", JSON.stringify(newCustomerQuestions));
      }

      await axios.get('customer/get')
      .then(response => {
        let customers = response.data.customers
        localStorage.setItem("customers", JSON.stringify(customers));
      })
      .catch(error => {
        if(error.response.status === 401) {
          localStorage.removeItem('xACGameToken');
          localStorage.removeItem('token-expires');
          localStorage.removeItem('user-lang');
        }
      })
      await axios.get('question/get')
      .then(response => {
        let questions = response.data.questions
        localStorage.setItem("questions", JSON.stringify(questions));
      })
    },
    cached() {
    },
    updatefound() {
    },
    updated() {
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    },
    offline() {
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  })
}