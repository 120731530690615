<template>
  <div>
    <div class="row mt-5">
      <div v-for="(image, index) in questionImages" class="col-4 d-flex justify-content-center">
        <div :id="`image${index}`" class="img-wrapper">
          <img :src="`/img/${image.data.toLowerCase()}`" :alt="`Image-${index + 1}`" :id="index" class="w-20vh draggable">
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <img src="/src/assets/images/ac-arrow-down.svg" class="m-3 p-2 arrow-down" alt="Gib deine Lösung ab">
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div v-for="dropzone in questionAnswerAmount" class="col-4 d-flex justify-content-center">
        <div class="stackslot dropzone w-20vh"></div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs';

export default {
  name: "GameDrag",
  props: {
    questionImages: "Array",
    questionAnswerAmount: "Number"
  },
  methods: {
    interactInit(questionImages) {
      // init starting position
      let startPosX = null;
      let startPosY = null;

      interact('.dropzone').dropzone({
        // Require a 25% element overlap for a drop to be possible
        overlap: 0.25,

        // listen for drop related events:
        ondropactivate: function (event) {
          // add active dropzone feedback
          event.target.classList.add('drop-active');
          event.relatedTarget.classList.add('dragging');
          startPosX = event.relatedTarget.getBoundingClientRect().x;
          startPosY = event.relatedTarget.getBoundingClientRect().y;
        },
        ondragenter: function (event) {
          let draggableElement = event.relatedTarget;
          let dropzoneElement = event.target;

          // feedback the possibility of a drop
          dropzoneElement.classList.add('drop-target');
          draggableElement.classList.add('can-drop');
        },
        ondragleave: function (event) {
          // remove the drop feedback style
          event.target.classList.remove('drop-target');
          event.relatedTarget.classList.remove('can-drop');
          event.relatedTarget.classList.remove('dropped');
          event.target.classList.remove('dropped');
        },
        ondrop: (event) => {
          event.relatedTarget.classList.add('dropped');

          let dropZone = event.target.getBoundingClientRect();
          let image = event.relatedTarget;

          // init newPos of image
          let newPosX = null;
          let newPosY = null;

          // set newPos of image to match dropzone position
          newPosX = (dropZone.x - startPosX);
          newPosY = (dropZone.y - startPosY);

          image.style.transform = 'translate(' + newPosX + 'px, ' + newPosY + 'px)';
          image.setAttribute('data-x', newPosX);
          image.setAttribute('data-y', newPosY);

          let wrapper = document.getElementById(`image${image.id}`);

          // check if answer is correct and add the matching classes
          if (questionImages[image.id].correct) {
            image.classList.add('correct');
            wrapper.classList.add('correct');
            document.documentElement.style.setProperty('--pseudo-offset-x', newPosX + 'px');
            document.documentElement.style.setProperty('--pseudo-offset-y', newPosY + 'px');

            // check if the question has been answered wrong before
            let wrong = document.querySelectorAll('img.dropped.false');
            if (wrong.length === 0) {
              if (localStorage.getItem('correctAnswers')) {
                let correctAnswers = localStorage.getItem('correctAnswers');
                correctAnswers++;
                localStorage.setItem('correctAnswers', correctAnswers);
              } else {
                localStorage.setItem('correctAnswers', 1);
              }
              this.$emit('answeredQuestion', true);
            } else {
              this.$emit('answeredQuestion', false);
            }

            // emit to parent if answer is correct
            this.$emit('selectedAnswer', true);

          } else {
            image.classList.add('false');
            wrapper.classList.add('false');
            image.style.transform = 'translate(' + 0 + 'px, ' + 0 + 'px)';
            image.setAttribute('data-x', 0);
            image.setAttribute('data-y', 0);
          }
        },
        ondropdeactivate: function (event) {
          event.target.classList.remove('drop-active');
          event.target.classList.remove('drop-target');
          event.relatedTarget.classList.remove('dragging');
          if (event.relatedTarget.classList.contains('can-drop')) {
            return
          } else {
            event.relatedTarget.classList.remove('false');
            event.relatedTarget.classList.remove('correct');
            event.relatedTarget.style.transform = 'translate(' + 0 + 'px, ' + 0 + 'px)';
            event.relatedTarget.setAttribute('data-x', 0);
            event.relatedTarget.setAttribute('data-y', 0);
          }
        }

      })

      interact('.draggable')
        .draggable({
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: '#app'
            })
          ],
          autoScroll: false,
          listeners: {
            move: this.dragMoveListener
          }
        })
    },
    dragMoveListener(event) {
      let target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      // translate the element
      target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

      // update the posiion attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }
  },
  async mounted() {
    await this.interactInit(this.questionImages);
  },
  beforeUnmount() {
    this.$emit('selectedAnswer', false);
    interact('.dropzone').unset();
    interact('.draggable').unset();
  }
}

</script>

<style scoped>
.draggable {
  touch-action: none;
}

.drop-target {
  background-color: rgb(142 139 139);
}
</style>