<template>
  <div class="logo-wrapper d-none d-lg-block">
    <img src="/src/assets/images/ac-logo.png" class="logo-item" alt="Logo Al Capone">
  </div>
  <div class="container section-shape">
    <div class="row">
      <!-- Spalte links -->
      <div class="col-12 col-md-5 pb-5">
        <div class="bg-img-logo d-flex justify-content-center my-4 ms-n5">
          <h1 class="text-uppercase text-white fw-bold py-5">{{ $t('game.question') }} {{ questionId }}</h1>
        </div>
        <h2 class="game-question text-uppercase fw-semibold me-5 mb-4">{{ question.text }}</h2>
        <p v-if="question.category === 'variant'" class="game-description text-white text-uppercase fw-medium me-5">
          {{ $t('game.descriptionDrag') }}
        </p>
        <p v-else class="game-description text-white text-uppercase fw-medium me-5">{{
            $t('game.descriptionRadio')
          }}</p>
      </div>
      <!-- Spalte rechts -->
      <div class="col-12 col-md-6 offset-0 offset-md-1 mb-2 mb-md-0">
        <gameDrag v-if="question.type === 'drag'" :question-images="question.answers"
                  :question-answer-amount="question.correct" @selectedAnswer="onDragValue"
                  @answeredQuestion="onAnsweredQuestions"/>
        <gameRadio v-else :question-image="question.image" :question-answers="question.answers"
                   @correctAnswer="onRadioValue" @answeredQuestion="onAnsweredQuestions"/>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-6 offset-md-6">
        <div class="d-flex justify-content-center mb-5 mb-md-0">
          <router-link v-if="questionId < 5 && (checkedRadioAnswer === true || selectedDragAnswer === true)"
                       :to="`${questionId + 1}`"
                       class="btn btn-lg btn-primary btn-md-white fs-5 text-uppercase fw-medium px-5">
            {{ $t('game.buttons.next') }}
          </router-link>
          <button type="button"
                  v-if="questionId === 5 && (checkedRadioAnswer === true || selectedDragAnswer === true)"
                  data-bs-toggle="modal" data-bs-target="#modal-congrats"
                  class="btn btn-lg btn-primary btn-md-white text-uppercase fw-medium px-5" @click="openModal">
            {{ $t('game.buttons.done') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modal-congrats" tabindex="-1" aria-hidden="true" aria-labelledby="modal-congrats">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content align-items-center">
        <div class="bg-primary w-100 p-4">
          <h1 class="display-3 fw-bold text-uppercase text-center">{{ $t('game.modal.congrats') }}</h1>
          <h2 class="display-6 fw-bold text-uppercase text-center">{{ $t('game.modal.description') }}</h2>
          <p class="lead fw-bold text-uppercase text-center mt-3">
            {{ $t('game.modal.correctAnswersStart') }} {{ correctAnswers }}
            <span v-if="correctAnswers > 1 || correctAnswers === 0">{{ $t('game.modal.questions') }}</span>
            <span v-if="correctAnswers === 1">{{ $t('game.modal.question') }}</span>
            {{ $t('game.modal.correctAnswersEnd') }}
          </p>
        </div>
        <div @click="endGame()" class="btn btn-lg btn-primary btn-md-white text-uppercase fw-medium mt-5"
             data-bs-dismiss="modal">{{ $t('game.modal.buttons.close') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import gameDrag from './GameDrag.vue';
import gameRadio from './GameRadio.vue';
import {
  useAxios
} from '@/axios';

const axios = useAxios;

export default {
  name: "Game",
  components: {
    gameDrag,
    gameRadio
  },
  data() {
    return {
      questions: [],
      checkedRadioAnswer: null,
      selectedDragAnswer: null,
      correctAnswers: 0,
      isOffline: false
    }
  },
  computed: {
    // get questionId via route params
    questionId() {
      return parseInt(this.$route.params.id);
    },
    // get index of questions related to questionId
    question() {
      return this.questions.find(question => (this.questions.indexOf(question) + 1) === this.questionId);
    },
  },
  mounted() {
    // check for network status
    if (window.navigator.onLine) {
      this.isOffline = false;
    } else {
      this.isOffline = true;
    }

    window.addEventListener('offline', () => {
      this.isOffline = true;
    });

    window.addEventListener('online', () => {
      this.isOffline = false;
    });
  },
  methods: {
    // route to thanks page and store answered questions
    async endGame() {
      // collect new answered questions
      let newAnsweredQuestions = {};
      for (let question of this.questions) {
        newAnsweredQuestions[question.id.toString()] = {"on_first_attempt": question.answeredCorrect};
      }
      let newEntry = {
        customer: localStorage.getItem("currentCustomer"),
        questions: newAnsweredQuestions,
        solved: true
      };
      // if localStorage entry already exists, update it or otherwise just set it
      if (await JSON.parse(localStorage.getItem("newCustomerQuestions"))) {
        let newCustomerQuestions = await JSON.parse(localStorage.getItem("newCustomerQuestions"));
        let entryExist = false;

        for (const customerQuestions of newCustomerQuestions) {
          if (customerQuestions.customer === localStorage.getItem("currentCustomer")) {
            entryExist = true;
            newCustomerQuestions[newCustomerQuestions.findIndex(entry => entry.customer === customerQuestions.customer)].questions = {
              ...customerQuestions.questions,
              ...newEntry.questions
            };
            newCustomerQuestions[newCustomerQuestions.findIndex(entry => entry.customer === customerQuestions.customer)].solved = true;
          }
        }
        if (!entryExist) {
          newCustomerQuestions.push(newEntry);
        }

        localStorage.setItem("newCustomerQuestions", JSON.stringify(newCustomerQuestions));
      } else {
        localStorage.setItem("newCustomerQuestions", JSON.stringify([newEntry]));
      }

      // sync questions if not offline
      if (!this.isOffline) {
        if (await JSON.parse(localStorage.getItem("newCustomerQuestions"))) {
          let newCustomerQuestions = await JSON.parse(localStorage.getItem("newCustomerQuestions"));

          while (newCustomerQuestions.length > 0) {
            let customerQuestions = newCustomerQuestions.pop();
            await axios.post(`/customer/questions/set/${customerQuestions.customer}`, {
              questions: customerQuestions.questions
            });
          }
          localStorage.setItem("newCustomerQuestions", JSON.stringify(newCustomerQuestions));
        }
      }
      this.correctAnswers = 0;
      router.push('/game/thanks');
    },
    // get value of radio questions
    onRadioValue(value) {
      this.checkedRadioAnswer = value;
    },
    // get value of drag&drop question
    onDragValue(value) {
      this.selectedDragAnswer = value;
    },
    onAnsweredQuestions(value) {
      this.question.answeredCorrect = value;
      console.log(value);
    },
    // remove localStorage entry and open Modal
    openModal() {
      if (localStorage.getItem('correctAnswers')) {
        this.correctAnswers = localStorage.getItem('correctAnswers')
      }

      localStorage.removeItem("newQuestions");
      if (localStorage.getItem('correctAnswers')) {
        localStorage.removeItem('correctAnswers');
      }
    }
  },
  beforeMount() {
    // get all questions and questions for game
    let allQuestions = JSON.parse(localStorage.getItem("questions"));
    let newQuestions = JSON.parse(localStorage.getItem("newQuestions"));

    for (const newQuestion of newQuestions) {
      let newQuestionVariant = allQuestions[0].variant.find(question => question.id === newQuestion);
      let newQuestionTargetGroup = allQuestions[1].targetGroup.find(question => question.id === newQuestion);
      let newQuestionUsp1 = allQuestions[2].usp1.find(question => question.id === newQuestion);
      let newQuestionUsp2 = allQuestions[3].usp2.find(question => question.id === newQuestion);
      let newQuestionUsp3 = allQuestions[4].usp3.find(question => question.id === newQuestion);
      this.questions.push(
          newQuestionVariant,
          newQuestionTargetGroup,
          newQuestionUsp1,
          newQuestionUsp2,
          newQuestionUsp3
      );
    }

    // delete all undefined elements of array
    this.questions = this.questions.filter(function (question) {
      return question !== undefined;
    });

    // set the first letter of every answer to uppercase
    for (const question of this.questions.slice(1)) {
      if (question.type === "choice") {
        for (const answer of question.answers) {
          answer.data = answer.data.charAt(0).toUpperCase() + answer.data.slice(1);
        }
      }
    }
  }
}
</script>