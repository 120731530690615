<template>
  <div class="logo-wrapper d-none d-md-block">
    <img src="/src/assets/images/ac-logo.png" class="logo-item" alt="Logo Al Capone">
  </div>
  <div class="lang-chooser">
    <select class="form-select mb-3" id="selectLang" aria-label="Change Language" v-model="$i18n.locale">
      <option value="de">DE</option>
      <option value="en">EN</option>
    </select>
  </div>
  <div class="container section-shape-bg">
    <div class="row">
      <!-- Spalte links -->
      <div class="col-12 col-md-4">
        <h1 class="text-uppercase text-dark fw-bold my-5">{{ $t('login.loginStart') }}.</h1>
        <h2 class="h3 text-uppercase text-white fw-medium">
          {{ $t('login.description') }}
        </h2>
      </div>
      <!-- Spalte rechts -->
      <div class="col-12 col-md-6 offset-md-2 mt-4 mt-md-0">
        <h2 class="text-white fw-medium text-uppercase mb-5 mt-50">{{ $t('login.enterLogin') }}</h2>
        <form>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
              v-model="loginData.username">
            <label for="floatingInput">{{ $t('login.mail') }}</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control mb-3" id="floatingPassword" :placeholder="$t('login.password')"
              v-model="loginData.password">
            <label for="floatingPassword">{{ $t('login.password') }}</label>
          </div>
          <div v-if="loginError" class="text-white">{{ $t('login.loginError') }}</div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary btn-md-white text-uppercase fw-medium px-3" @click.prevent.stop="login">
              {{ $t('login.buttons.login') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { useAxios } from '@/axios';
const axios = useAxios;

export default {
  name: "Login",
  data() {
    return {
      loginData: {
        username: null,
        password: null
      },
      loginError: false,
    }
  },
  methods: {
    login() {
      axios.post('user/login', {
        username: this.loginData.username,
        password: this.loginData.password
      }).then(response => {
        // set expiry token in localStorage of 1 week
        localStorage.setItem('token-expires', new Date().setDate(new Date().getDate() + 7));
        localStorage.setItem('user-lang', response.data.lang);
        router.push('/selection')
      }).catch(error => {
        this.loginError = true;
      });
    }
  },
  mounted() {
    if (localStorage.getItem('questions')) {
      localStorage.removeItem('questions');
    }

    if (localStorage.getItem('customers')) {
      localStorage.removeItem('customers');
    }
  }
}
</script>