<template>
  <div>
    <div class="row mt-5">
      <div class="col-12 d-flex justify-content-center">
        <img :src="`/img/${questionImage}`" alt="Picture 01" class="w-60">
      </div>
    </div>
    <div class="row game-radio mt-4 mb-3" :class="{ 'answered': checkedAnswer }">
      <div class="col-12 lead">
        <div class="form-check mb-2">
          <input class="form-check-input" type="radio" name="gameRadios" value="0" id="0" v-model="checkedAnswer"
            @click="solveQuestion">
          <label v-html="questionAnswers[0].data" class="form-check-label" for="0">
          </label>
        </div>
        <div class="form-check mb-2">
          <input class="form-check-input" type="radio" name="gameRadios" value="1" id="1" v-model="checkedAnswer"
            @click="solveQuestion">
          <label v-html="questionAnswers[1].data" class="form-check-label" for="1">
          </label>
        </div>
        <div class="form-check mb-2">
          <input class="form-check-input" type="radio" name="gameRadios" value="2" id="2" v-model="checkedAnswer"
            @click="solveQuestion">
          <label v-html="questionAnswers[2].data" class="form-check-label" for="2">
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameRadio",
  props: {
    questionImage: "string",
    questionAnswers: "Array"
  },
  data() {
    return {
      checkedAnswer: "",
      correctAnswer: false
    }
  },
  computed: {
    // get routeId
    routeId() {
      return parseInt(this.$route.params.id);
    },
    inputs() {
      return document.querySelectorAll('input');
    }
  },
  watch: {
    // if routeId changes, reload the page to reset the form
    routeId() {
      this.checkedAnswer = "";
      this.correctAnswer = false;
      this.$emit('correctAnswer', false)
      let elements = document.querySelectorAll(".answered");
      for (const element of elements) {
        element.classList.remove("answered", "correct", "false");
      }
      for (const input of this.inputs) {
        if (input.hasAttribute('disabled')) {
          input.removeAttribute('disabled');
        }
      }
    },
    // emit to parent if answer is correct
    correctAnswer(value) {
      this.$emit('correctAnswer', value);
    }
  },
  methods: {
    solveQuestion(e) {
      e.target.labels[0].classList.add('answered');
      e.target.classList.add('answered');

      // check if selected answer is correct and add matching classes
      if (this.questionAnswers[e.target.id].correct) {
        e.target.labels[0].classList.add('correct');
        e.target.classList.add('correct');
        this.correctAnswer = true;

        for (const input of this.inputs) {
          if (!input.classList.contains('correct')) {
            input.setAttribute('disabled', '');
          }
        }

        // check if the question has been answered wrong before
        let wrong = document.querySelectorAll('label.false');

        if (wrong.length === 0) {
          if (localStorage.getItem('correctAnswers')) {
            let correctAnswers = localStorage.getItem('correctAnswers');
            correctAnswers++
            localStorage.setItem('correctAnswers', correctAnswers);
          } else {
            localStorage.setItem('correctAnswers', 1);
          }
          this.$emit('answeredQuestion', true);
        } else {
          this.$emit('answeredQuestion', false);
        }
      } else {
        e.target.labels[0].classList.add('false');
        e.target.classList.add('false');
      }
    }
  },
  beforeMount() {
    this.$emit('correctAnswer', false);
  }
}
</script>