<template>
  <div class="logo-wrapper d-none d-lg-block">
    <img src="/src/assets/images/ac-logo.png" class="logo-item" alt="Logo Al Capone">
  </div>
  <div class="container section-shape-bg">
    <div class="row">
      <!-- Spalte links -->
      <div class="col-12 col-md-4">
        <h1 class="text-uppercase fw-bold position-md-fixed hyphens-auto my-5" v-html="$t('customer.customerOverview')">
        </h1>
      </div>
      <!-- Spalte rechts -->
      <div class="col-12 col-md-6 offset-md-2">
        <h2 class="text-white fw-medium text-uppercase mb-3 mt-5">{{ $t('customer.customer') }}</h2>
        <p class="text-white fs-5">{{ customer.name }}</p>
        <p class="text-white fs-5">{{ customer.contact_person }}</p>
        <button @click.prevent="newGame()" class="btn btn-primary btn-md-white text-uppercase fw-medium px-3 mb-3">
          {{ $t('customer.buttons.newGame') }}
        </button>
        <router-link to="/selection" class="btn btn-primary btn-md-white text-uppercase fw-medium px-3 mb-3 ms-3">
          {{ $t('customer.buttons.back') }}
        </router-link>
        <p v-show="allQuestionsAnswered" class="text-danger fs-5 mb-5">{{ $t('customer.answeredAll') }}</p>
        <!-- <h2 class="text-dark fw-medium text-uppercase mt-5 mb-3">Recent Questions</h2>
        <div v-if="answeredQuestions" v-for="answeredQuestion in answeredQuestions">
          <p>{{ answeredQuestion.text }}</p>
        </div> -->
        <!-- <div class="d-flex justify-content-md-center mb-5">
          <router-link to="/selection" class="btn btn-primary btn-md-white text-uppercase fw-medium px-3 mt-2">Back</router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import {
  useAxios
} from '@/axios';

const axios = useAxios;

export default {
  name: "Customer",
  data() {
    return {
      customer: {},
      answeredQuestions: [],
      newQuestions: [],
      isOffline: false,
      allQuestionsAnswered: false
    }
  },
  methods: {
    // get ids of questionset
    getIds(array) {
      let ids = [];
      for (let i = 0; i < array.length; i++) {
        ids.push(array[i]["id"]);
      }
      return ids;
    },
    // randomize questions which haven't been answered before
    randomizeQuestions(questionSet) {
      let randomQuestion = null;
      let answeredQuestions = this.customer.questions;
      let questions = this.getIds(questionSet);
      let notAnsweredIds = [];

      if (!answeredQuestions.length) {
        randomQuestion = questions[Math.floor(Math.random() * questionSet.length)];
      } else {
        for (const id in questions) {
          if (!answeredQuestions.includes(questions[id])) {
            notAnsweredIds.push(questions[id]);
          }
        }
        randomQuestion = notAnsweredIds[Math.floor(Math.random() * notAnsweredIds.length)];
      }

      return randomQuestion ? randomQuestion : false;
    },
    // start new game
    async newGame() {
      let questions = JSON.parse(localStorage.getItem("questions"));
      this.newQuestions.push(this.randomizeQuestions(questions[1].targetGroup));
      this.newQuestions.push(this.randomizeQuestions(questions[0].variant));
      this.newQuestions.push(this.randomizeQuestions(questions[2].usp1));
      this.newQuestions.push(this.randomizeQuestions(questions[3].usp2));
      this.newQuestions.push(this.randomizeQuestions(questions[4].usp3));

      let allAnsweredQuestions = this.customer.questions;
      for (const newQuestion of this.newQuestions) {
        allAnsweredQuestions.push(newQuestion);
      }

      // delete all undefined elements of array
      this.newQuestions = this.newQuestions.filter(function (question) {
        return question !== undefined;
      });

      for (const newQuestion of this.newQuestions) {
        if (typeof newQuestion === "boolean") {
          this.allQuestionsAnswered = true;
        }
      }
      if (this.allQuestionsAnswered !== true) {
        localStorage.setItem("newQuestions", JSON.stringify(this.newQuestions));

        // set new questions to customer
        let customers = await JSON.parse(localStorage.getItem("customers"));
        let customer = customers.findIndex(customer => customer.id === this.customer.id);
        customers[customer].questions = allAnsweredQuestions;
        localStorage.setItem("customers", JSON.stringify(customers));
        localStorage.setItem("currentCustomer", this.customer.id);

        // route to game
        router.push('/game/question/1');
      }
    }
  },
  async beforeMount() {
    // get customerId via route params
    let customers = await JSON.parse(localStorage.getItem("customers"));
    let customerId = parseInt(this.$route.params.id);
    this.customer = customers.find(customer => customer.id === customerId);

    let questions = await JSON.parse(localStorage.getItem("questions"));

    // get answered questions of customer
    for (const answeredQuestion in this.customer.questions) {
      let answeredQuestionTextVariant = questions[0].variant.find(question => question.id === this.customer.questions[
          answeredQuestion]);
      let answeredQuestionTextTargetGroup = questions[1].targetGroup.find(question => question.id === this.customer
          .questions[answeredQuestion]);
      let answeredQuestionTextUsp1 = questions[2].usp1.find(question => question.id === this.customer.questions[
          answeredQuestion]);
      let answeredQuestionTextUsp2 = questions[3].usp2.find(question => question.id === this.customer.questions[
          answeredQuestion]);
      let answeredQuestionTextUsp3 = questions[4].usp3.find(question => question.id === this.customer.questions[
          answeredQuestion]);
      this.answeredQuestions.push(
          answeredQuestionTextVariant,
          answeredQuestionTextTargetGroup,
          answeredQuestionTextUsp1,
          answeredQuestionTextUsp2,
          answeredQuestionTextUsp3
      );

      // delete all undefined elements of array
      this.answeredQuestions = this.answeredQuestions.filter(function (question) {
        return question !== undefined;
      });
    }
  },
  mounted() {
    // check for network status
    if (window.navigator.onLine) {
      this.isOffline = false;
    } else {
      this.isOffline = true;
    }

    window.addEventListener('offline', () => {
      this.isOffline = true;
    });

    window.addEventListener('online', () => {
      this.isOffline = false;
    });
  },
}
</script>