import './assets/main.scss'

import * as bootstrap from 'bootstrap';

import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {createI18n} from 'vue-i18n';
import './registerServiceWorker';
import messages from './translations.js';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
});

if (localStorage.getItem("user-lang")) {
    i18n.global.locale = localStorage.getItem("user-lang");
}

const app = createApp(App);

app.use(i18n);
app.use(router);

app.mount('#app');