<template>
  <div class="logo-wrapper d-none d-lg-block">
    <img src="/src/assets/images/ac-logo.png" class="logo-item" alt="Logo Al Capone">
  </div>
  <div class="container section-shape">
    <div class="row">
      <!-- Spalte links -->
      <div class="col-12 col-md-4">
        <h1 class="text-uppercase fw-bold hyphens-auto my-5">Privacy Policy</h1>
      </div>
      <!-- Spalte rechts -->
      <div class="col-12 col-md-6 offset-md-2">
        <h2 class="text-dark fw-medium text-uppercase mb-3 mt-5">Privacy Policy</h2>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
          et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
          sea
          takimata sanctus est Lorem ipsum dolor sit amet.</p>
      </div>
    </div>
  </div>
</template>