import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Game from '../views/Game.vue'
import Login from '../views/Login.vue'
import Selection from '../views/Selection.vue'
import Customer from '../views/Customer.vue'
import Thanks from '../views/Thanks.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'

const checkForToken = function(to, from, next) {
  if(localStorage.getItem('token-expires') < new Date().setDate(new Date().getDate())) {
    localStorage.removeItem('token-expires');
    localStorage.removeItem('xACGameToken');
  }
  
  if(localStorage.getItem('xACGameToken')) {
    next();
  } else {
    window.location.href = "/login";
  }
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      redirect: to => {
        if(localStorage.getItem('token-expires') < new Date().setDate(new Date().getDate())) {
          localStorage.removeItem('token-expires');
          localStorage.removeItem('xACGameToken');
        }

        if(localStorage.getItem('xACGameToken')) {
          return 'selection'
        } else {
          return 'login'
        }
      }
    },
    {
      path: '/game/question/:id',
      name: 'game',
      component: Game,
      beforeEnter: (to, from, next) => {
        if(localStorage.getItem('token-expires') < new Date().setDate(new Date().getDate())) {
          localStorage.removeItem('token-expires');
          localStorage.removeItem('xACGameToken');
        }

        if(localStorage.getItem('xACGameToken')) {
          if(localStorage.getItem('newQuestions')) {
            next();
          } else {
            window.location.href = "/selection";
          }
        } else {
          window.location.href = "/login";
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if(localStorage.getItem('token-expires') < new Date().setDate(new Date().getDate())) {
          localStorage.removeItem('token-expires');
          localStorage.removeItem('xACGameToken');
        }

        if(localStorage.getItem('xACGameToken')) {
          window.location.href = "/selection";
        } else {
          next();
        }
      }
    },
    {
      path: '/selection',
      name: 'selection',
      component: Selection,
      beforeEnter: (to, from, next) => {
        checkForToken(to, from, next);
      }
    },
    {
      path: '/selection/:id',
      name: 'customer',
      component: Customer,
      beforeEnter: (to, from, next) => {
        checkForToken(to, from, next);
      }
    },
    {
      path: '/game/thanks',
      name: 'thanks',
      component: Thanks,
      beforeEnter: (to, from, next) => {
        checkForToken(to, from, next);
      }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint,
      beforeEnter: (to, from, next) => {
        checkForToken(to, from, next);
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
      beforeEnter: (to, from, next) => {
        checkForToken(to, from, next);
      }
    }
  ]
})

export default router
