const messages = {
    en: {
        login: {
            loginStart: 'Login to start the game.',
            description: 'In this game we test your knowledge about our product line.',
            enterLogin: 'Enter your login details to start the game',
            mail: 'E-Mail',
            password: 'Password',
            loginError: 'No valid Login',
            buttons: {
                login: 'Login'
            }
        },
        selection: {
            customerSelection: 'Customer-<br>selection',
            description: 'Select your customer and player to start the game.',
            createCustomer: 'Create a new customer',
            customerName: 'Customer name',
            customerNumber: 'Customer number',
            selectCustomer: '... or select a customer',
            customer: 'Customer',
            selectCustomerOption: 'Select customer...',
            buttons: {
                create: 'create',
                next: 'next',
                sync: 'sync'
            }
        },
        customer: {
            customerOverview: 'Customer-<br>overview',
            customer: 'Customer:',
            answeredAll: 'You have already answered all questions.',
            buttons: {
                newGame: 'New game',
                back: 'Back',
            }
        },
        game: {
            question: 'Question',
            descriptionDrag: 'Drag the matching package into the respective slot',
            descriptionRadio: 'Choose the right answer',
            buttons: {
                next: 'Next',
                done: 'Done',
            },
            modal: {
                congrats: 'Congrats!',
                description: 'You are an al capone expert!',
                correctAnswersStart: 'You answered',
                question: 'question',
                questions: 'questions',
                correctAnswersEnd: 'correct.',
                buttons: {
                    close: 'Close'
                }
            },
            thanks: {
                greatWork: 'Great work',
                description: 'Thanks for your time',
                buttons: {
                    back: 'Back'
                }
            }
        }
    },
    de: {
        login: {
            loginStart: 'Logge dich ein um das Spiel zu starten.',
            description: 'In diesem Spiel testen wir dein Wissen über unsere Produktlinie.',
            enterLogin: 'Gib deine Anmeldedaten ein, um das Spiel zu starten',
            mail: 'E-Mail',
            password: 'Passwort',
            loginError: 'Keine validen Anmeldedaten',
            buttons: {
                login: 'Einloggen'
            }
        },
        selection: {
            customerSelection: 'Kunden-<br>auswahl',
            description: 'Wähle deinen Kunden und Spieler aus, um das Spiel zu starten.',
            createCustomer: 'Erstelle einen Kunden',
            customerName: 'Kundenname',
            customerNumber: 'Kundennummer',
            selectCustomer: '... oder wähle einen Kunden aus',
            customer: 'Kunde',
            selectCustomerOption: 'Wähle Kunden...',
            buttons: {
                create: 'Erstellen',
                next: 'Weiter',
                sync: 'Synchronisieren'
            }
        },
        customer: {
            customerOverview: 'Kunden-<br>übersicht',
            customer: 'Kunde:',
            answeredAll: 'Du hast bereits alle Fragen beantwortet.',
            buttons: {
                newGame: 'Neues Spiel',
                back: 'Zurück',
            }
        },
        game: {
            question: 'Frage',
            descriptionDrag: 'Ziehe das passende Bild in den entsprechenden Slot.',
            descriptionRadio: 'Wähle die richtige Antwort',
            buttons: {
                next: 'Weiter',
                done: 'Fertig',
            },
            modal: {
                congrats: 'Herzlichen Glückwunsch!',
                description: 'Du bist ein Al Capone Experte!',
                correctAnswersStart: 'Du hast',
                question: 'Frage',
                questions: 'Fragen',
                correctAnswersEnd: 'richtig beantwortet.',
                buttons: {
                    close: 'Schließen'
                }
            },
            thanks: {
                greatWork: 'Gute Arbeit',
                description: 'Danke für deine Zeit',
                buttons: {
                    back: 'Zurück'
                }
            }
        }
    }
};

export default messages;