<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center flex-column gap-4 vh-100">
        <div class="thanks"></div>
        <div class="d-flex flex-column align-items-center">
          <h1 class="display-2 text-uppercase fw-bold">{{ $t('game.thanks.greatWork') }}</h1>
          <h2 class="display-6 text-uppercase fw-medium">{{ $t('game.thanks.description') }}</h2>
        </div>
        <router-link to="/selection" class="btn btn-light btn-thanks text-uppercase fw-medium px-3 mb-5">
          {{ $t('game.thanks.buttons.back') }}
        </router-link>
      </div>
    </div>
  </div>
  <div class="position-absolute bottom-0 logo-banner">
    <img src="/img/banner-logo.webp" class="w-100 position-relative" alt="">
  </div>
</template>

<script>
export default {
  name: "Thanks",
}
</script>